import { render, staticRenderFns } from "./PrivacyPolicy.vue?vue&type=template&id=3031c834&scoped=true&"
import script from "./PrivacyPolicy.vue?vue&type=script&lang=js&"
export * from "./PrivacyPolicy.vue?vue&type=script&lang=js&"
import style0 from "./PrivacyPolicy.vue?vue&type=style&index=0&id=3031c834&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3031c834",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/dev/workspace/Maxwork/maxwork-msf-1-PRODUCTION/maxwork-msf-01-PROD-web-cms-build-n-deploy/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3031c834')) {
      api.createRecord('3031c834', component.options)
    } else {
      api.reload('3031c834', component.options)
    }
    module.hot.accept("./PrivacyPolicy.vue?vue&type=template&id=3031c834&scoped=true&", function () {
      api.rerender('3031c834', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/view/privacyPolicy/PrivacyPolicy.vue"
export default component.exports