<template>
  <section class="privacy-policy-section">
    <div class="service-main">
      <h1>개인정보처리방침</h1>
      <h2>MAXWORK SMART FACTORY 서비스 개인정보처리방침</h2>
    </div>
    <div class="service-content">
      <p>
        MAXWORK SMART FACTORY 서비스 개인정보처리방침
        <br />
        <br />
        (주)맥스트(이하 “회사”라 합니다)는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법,
        통신비밀보호법, 전기통신사업법 등 정보통신서비스제공자가 준수하여야 할 관련 법규상의 개인정보보호 규정을
        준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 이용자의 개인정보보호에 최선을 다하고 있습니다.
        <br />
        <br />
        제1조(개인정보의 수집/이용 목적, 수집/이용하는 개인정보 항목 및 보유기간)
        <br />
        ① 회사는 본 개인정보처리방침에서 정하는 목적을 위하여 개인정보를 처리합니다. 회사가 처리하고 있는 개인정보는
        다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 정보통신망 이용촉진 및 정보보호 등에
        관한 법률 제22조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
        <br />
        ② 회사는 법령에 따른 개인정보 보유/이용기간 또는 이용자로부터 개인정보를 수집시에 동의받은 개인정보
        보유/이용기간 내에서 개인정보를 처리/보유합니다.
        <br />
        ③ 개인정보를 수집하는 방법은 다음 각 호의 방법에 의합니다.
        <br />
        1. MAXWORK SMART FACTORY 서비스 이용 신청을 통한 정보수집
        <br />
        2. 이용자 이벤트 응모를 통한 정보수집
        <br />
        3. 고객문의 게시판을 통한 정보수집
        <br />
        4. 서비스 이용과정이나 사업처리 과정을 통한 정보수집
        <br />
        5. 전화/팩스를 통한 정보수집
        <br />
        ④ 각각의 처리하는 개인정보 항목 및 보유기간은 다음과 같습니다.
        <br />
        <br />
        <img src="/images/privacy_policy_1.png" alt="privacy-policy" />
        <br />
        <br />
        ⑤ 전항의 보유기간에도 불구하고, 다음 각 호의 사유에 해당하는 경우에는 이용자의 회원 탈퇴가 이루어지더라도 해당
        사유 종료시까지 개인정보를 보유합니다.
        <br />
        1. 관계 법령 위반에 따른 수사, 조사 등이 진행중인 경우: 해당 수사, 조사 종료시까지
        <br />
        2. 홈페이지 이용에 따른 채권, 채무관계가 존재하는 경우: 해당 채권, 채무관계 청산시까지
        <br />
        ⑥ 이용자는 회사가 수집하는 개인정보의 항목 및 수집 방법에 동의를 거부할 수 있으며, 거부 시 이용자에게 제공되는
        서비스 이용의 일부가 제한될 수 있습니다.
        <br />
        ⑦ 회사는 1년의 기간 동안 사용내역이 없는 이용자(이하 “휴면회원”이라 합니다)에 대하여 기간 만료 30일 전까지
        전자우편ㆍ서면ㆍ모사전송ㆍ전화 또는 이와 유사한 방법으로 개인정보가 파기되는 사실, 기간 만료일 및 파기되는
        개인정보의 항목 등에 대한 통지를 하며, 일정 기간 내에 답변이 없을 경우 휴면회원은 회원자격을 상실할 수 있습니다.
        <br />
        <br />
        제2조 (개인정보 처리의 위탁)
        <br />
        ① 회사는 원칙적으로 제3자에게 개인정보 처리를 위탁하지 않습니다. 서비스 제공을 위해 필요한 업무 중 일부에 한하여
        이용자의 개인정보를 외부에 위탁하여 처리하고 있으며 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수
        있도록 하고 있습니다. 회사가 개인정보를 위탁할 경우에는 위탁하는 업무의 내용과 개인정보 처리업무 수탁자에 관하여
        회사의 홈페이지에 지속적으로 게재하는 방법을 통하여 이용자가 언제든지 쉽게 확인할 수 있도록 하고 개인정보가
        안전하게 관리될 수 있도록 하겠습니다.
        <br />
        ②회사가 결제 시스템을 도입할 때 결제 대행 업체에게 결제를 위해 필요한 개인정보처리와 관련한 업무를 위탁할
        예정입니다.
        <br />
        <br />
        제3조(개인정보의 제3자 제공)
        <br />
        회사는 개인정보를 제1조 개인정보의 수집/이용 목적에서 고지한 범위 내에서 사용하며, 원칙적으로 이용자의 사전 동의
        없이는 동 범위를 초과하여 이용하거나 개인정보를 외부에 공개하지 않습니다. 다만, 아래 각 호의 경우에는 예외적으로
        개인정보를 제3자에게 제공할 수 있으며 이 경우 회사는 이용자에게 개인정보를 제공받는 자, 개인정보를 제공받는 자의
        개인정보 이용 목적, 제공하는 개인정보의 항목, 개인정보를 제공받는 자의 개인정보 보유 및 이용 기간, 동의를 거부할
        권리가 있다는 사실 및 동의 거부에 따른 불이익이 있는 경우에는 그 불이익의 내용을 알리고, 동의를 받겠습니다.
        <br />
        1. 이용자가 사전에 동의한 경우
        <br />
        2. 법령의 규정에 의거하거나, 수사목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
        <br />
        <br />
        제4조 (이용자의 권리/의무 및 그 행사방법)
        <br />
        ① 이용자 또는 법정 대리인은 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.
        <br />
        1. 개인정보 열람 요구
        <br />
        2. 오류 등이 있을 경우 정정 요구
        <br />
        3. 삭제 요구
        <br />
        4. 처리정지 요구
        <br />
        5. 개인정보처리에 대한 동의에 대한 철회
        <br />
        ② 전항에 따른 권리 행사는 회사에 대해 「정보통신망 이용촉진 및 정보보호 등에 관한 법률 시행규칙」 별지 제8호
        서식(개인정보 열람, 정정·삭제, 처리정지 요구서)에 따라 서면, 전자우편, 모사전송(FAX), 웹사이트 내 설정 탭 등을
        통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다.
        <br />
        ③ 회사는 이용자의 요청에 의해 해지 또는 삭제된 개인정보는 제1조에 명시된 바에 따라 처리하고 그 외의 용도로 열람
        또는 이용할 수 없도록 처리하고 있습니다. 이용자가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에
        회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를
        제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
        <br />
        ④ 제1항에 따른 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이와 같이
        이용자의 법정 대리인이 이용자의 개인정보의 열람 및 변경을 원할 경우 문의 메일을 통해 문의 주시거나, 대표번호로
        전화하여 권리행사를 할 수 있고 이 경우 개인정보보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야
        합니다.
        <br />
        ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를
        요구할 수 없습니다.
        <br />
        ⑥ 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여 제한될 수 있습니다.
        <br />
        ⑦ 회사는 열람 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를
        확인합니다.
        <br />
        <br />
        제5조 (개인정보 파기절차 및 방법)
        <br />
        ① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를
        파기합니다.
        <br />
        ② 이용자로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라
        개인정보를 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여
        보존하고, 해당 기간 경과 후 지체 없이 재생 불가능한 방법으로 파기합니다. 이때 별도 저장 관리되는 개인정보는
        법령에 정한 경우를 제외하고 다른 용도로 이용되지 않습니다.
        <br />
        ③ 구체적인 파기 절차, 기한 및 방법은 다음과 같습니다.
        <br />
        <br />
        <img src="/images/privacy_policy_3.png" alt="privacy-policy" />
        <br />
        <br />
        제6조(개인정보의 안전성 확보조치)
        <br />
        회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
        <br />
        1. 관리적 조치: 내부관리계획 수립/시행, 정기적 직원 교육, 개인정보 취급자의 제한 및 교육 등
        <br />
        2. 기술적 조치: 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화,
        보안프로그램 설치 등 해킹 및 컴퓨터 바이러스 등에 대비
        <br />
        3. 물리적 조치: 전산실, 자료보관실 등의 접근통제
        <br />
        <br />
        제7조 (개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항)
        <br />
        ① 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를
        사용합니다.
        <br />
        ② 쿠키는 MAXWORK SMART FACTORY 서비스의 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터 브라우저에게
        보내는 소량의 정보이며 이용자의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
        <br />
        1. 쿠키의 사용목적: 쿠키는 이용자의 접속빈도나 방문시간 등을 분석, 이용자의 취향과 관심분야 파악, 자취 추적,
        각종 이벤트 참여 정도 및 방문 횟수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스를 제공하기 위한 목적으로
        사용됩니다.
        <br />
        2. 회사는 서비스 이용 및 통계 분석을 목적으로 로그 분석툴인 Google Analytics를 사용하고 있습니다.
        <br />
        Google은 회사를 대신해 이용자의 이 웹사이트 사용을 분석하여 익명의 쿠키정보(인구통계학적 데이터 등)를 수집하고,
        그에 대한 보고서를 작성하며, 웹사이트 및 인터넷 사용과 관련된 추가 서비스를 회사에게 제공합니다. 이용자의
        브라우저에서 Google Analytics로 전송된 IP 주소는 Google의 다른 데이터와 병합되지 않습니다. 브라우저에서 Google로
        전송된 IP 주소는 Google의 다른 데이터와 통합되지 않습니다. 회사는 Google Analytics를 통해 개인을 식별할 수 있는
        정보는 수집하지 않으며, 수집한 정보를 다른 경로를 통해 얻은 개인식별 정보와 결합하지 않습니다. 외부 분석툴을
        통한 로그 분석을 중단하고 싶으신 경우, 안내 페이지
        <a href="https://tools.google.com/dlpage/gaoptout" :target="target">https://tools.google.com/dlpage/gaoptout</a>
        를 통해 설정을 해제하실 수 있습니다. Google Analytics를 통해 수집되는 정보의 처리는 Google 개인정보보호정책과
        Google Analytics 이용약관을 적용 받습니다.
        <br />
        - Google 개인정보처리방침:
        <a href="https://www.google.com/intl/ko/policies/privacy/" :target="target">
          https://www.google.com/intl/ko/policies/privacy/
        </a>
        <br />
        - Google Analytics 이용약관:
        <a href="https://www.google.com/analytics/terms/kr.html" :target="target">
          https://www.google.com/analytics/terms/kr.html
        </a>
        <br />
        3. 쿠키의 설치∙운영 및 거부: 이용자는 다음의 방법으로 쿠키 저장을 거부할 수 있습니다. 이용자가 사용하는 웹
        브라우저의 옵션을 설정함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을
        거부할 수 있습니다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.
        <br />
        - Internet Explorer의 경우
        <br />
        • [도구] 메뉴에서[인터넷 옵션]을 선택
        <br />
        • [개인정보]를 클릭
        <br />
        • [고급]을 클릭
        <br />
        • 쿠키 허용여부를 선택
        <br />
        - Chrome Browser의 경우
        <br />
        • 오른쪽 상단에서 더보기 메뉴의 [설정]을 선택합니다.
        <br />
        • 하단에서 [고급]을 선택합니다.
        <br />
        • [개인정보 보호 및 보안]에서 [콘텐츠 설정]을 선택합니다.
        <br />
        • [쿠키]를 선택하여 [사이트에서 쿠키 데이터 저장 및 읽기 허용]을 ‘사용중지’ 합니다.
        <br />
        <br />
        제8조 (개인정보보호 책임자 및 연락처)
        <br />
        ① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 이용자의 불만처리 및 피해구제
        등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
        <br />
        개인정보보호 책임자
        <br />
        이 름: 김승균
        <br />
        직 위: 상무
        <br />
        연락처: 02-585-9566, sgkim@maxst.com
        <br />
        개인정보보호 업무 및 관련 고충사항 처리 부서
        <br />
        담당자 유병우
        <br />
        연락처: 02-585-9566, bwyou@maxst.com
        <br />
        ② 이용자는 회사의 서비스를 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을
        개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 이용자의 문의에 대해 지체없이 답변 및 처리해드릴
        것입니다.
        <br />
        ③ 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
        <br />
        개인정보 침해신고센터 (한국인터넷진흥원 운영)
        <br />
        - 소관업무: 개인정보 침해사실 신고, 상담 신청
        <br />
        - 홈페이지: privacy.kisa.or.kr
        <br />
        - 전화: (국번없이) 118
        <br />
        - 주소: (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해신고센터
        <br />
        개인정보 분쟁조정위원회
        <br />
        - 소관업무: 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
        <br />
        - 홈페이지:
        <a href="https://www.kopico.go.kr" :target="target">https://www.kopico.go.kr</a>
        <br />
        - 전화: (국번없이) 1833-6972
        <br />
        - 주소: (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층
        <br />
        대검찰청 사이버범죄수사단: 02-3480-3573 (
        <a href="https://www.spo.go.kr" :target="target">https://www.spo.go.kr</a>
        )
        <br />
        <br />
        경찰청 사이버안전국: 182 (
        <a href="http://cyberbureau.police.go.kr" :target="target">http://cyberbureau.police.go.kr</a>
        )
        <br />
        <br />
        제9조 (개인정보처리방침의 변경)
        <br />
        ① 본 개인정보 처리방침은 2021.09.01.부터 적용됩니다.
        <br />
        ② 본 개인정보처리방침은 회사의 정책 및 관계 법령의 변경에 따라 변경될 수 있습니다.
        <br />
        <br />
        <br />
        <br />
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  props: [],
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    target() {
      return this.windowWidth < 769 ? '_self' : '_blank';
    },
  },
  created() {},
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  },
  watch: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss">
@import '../termsOfUse/TermsOfUse';
</style>
